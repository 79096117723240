import { Popover, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {
  CloudArrowUpIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'


const solutions = [
  {
    name: 'Engineering Data Services',
    description: '',
    href: '/engineeringdataservices',
    icon: CloudArrowUpIcon,
  },
  // {
  //   name: 'Automation',
  //   description: '',
  //   href: '/automation',
  //   icon:   Cog6ToothIcon,
  // },
]



export default function Navbar() {
  return(
    <>
       <header>
        <Popover>
          <div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex">
              <Popover>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`group inline-flex items-center rounded-md text-lg font-semibold leading-6 no-underline hover:text-blue-500
                      ${open  ? 'text-blue-500' : 'text-white'} `}
                    >
                      <span>Solutions</span>
                      <ChevronDownIcon
                        className={`ml-2 h-5 w-5 group-hover:text-blue-500
                        ${open  ? 'text-blue-500' : 'text-white'}`}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      {/* add back lg:max-w-2xl when there's more options */}
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:left-1/2 lg:ml-0 lg:max-w-[30rem] lg:-translate-x-[12rem]"> 
                        <div className="overflow-hidden rounded-lg shadow-lg">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">    {/* add back lg:grid-cols-2 when there more options */}
                            {solutions.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className=" flex items-start rounded-lg  hover:bg-gray-50"
                              >
                                <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-gradient-to-r from-blue-400 to-blue-700 text-white sm:h-12 sm:w-12">
                                  <item.icon className="h-6 w-6" aria-hidden="true" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </Popover.Group>
          </div>
        </Popover>
      </header>
    </>
  )
}