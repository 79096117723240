import Contactform from '../components/Contactform'

const CareerContact = () => {
    return (
        <>
            <Contactform/>
        </>
    );
}

export default CareerContact;
