export const positions = [
    // {
    //   id: 1,
    //   title: 'Engineer 1',
    //   type: 'Full-time',
    //   location: 'Remote',
    //   department: 'Engineering',
    //   closeDate: '2020-01-07',
    //   closeDateFull: 'January 7, 2020',
    //   jobDescription: 'Job ID 1 Description',
    // },
    // {
    //   id: 2,
    //   title: 'Engineer 1',
    //   type: 'Full-time',
    //   location: 'Remote',
    //   department: 'Engineering',
    //   closeDate: '2020-01-07',
    //   closeDateFull: 'January 7, 2020',
    //   jobDescription: 'Job ID 2 Description',
    // },
    // {
    //   id: 3,
    //   title: 'User Interface Designer',
    //   type: 'Full-time',
    //   location: 'Remote',
    //   department: 'Design',
    //   closeDate: '2020-01-14',
    //   closeDateFull: 'January 14, 2020',
    //   jobDescription: 'Job ID 3 Description',
    // },
  ]