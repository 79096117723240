import React from "react";
import { useLocation } from "react-router-dom"; //used to receive job object from CareerTable.jsx

const CareerDetail = () => {
  const location = useLocation(); //used to receive job object from CareerTable.jsx

  return (
    <div className="mx-32 flex-column">
      <div className="mt-32">

      <ul className="mx-4 flex justify-center">
        <li className="text-xl mx-4 p-4 bg-[#e5e5e5] text-center">
          <span className="pr-5 font-bold">Job ID:</span>
          <span className="">{location.state.position.id}</span>
        </li>
        <li className="text-xl mx-4 p-4 bg-[#e5e5e5] text-center">
          <span className="pr-5 font-bold">Job Type:</span>
          <span className=" ">{location.state.position.type}</span>
        </li>
        <li className="text-xl mx-4 p-4 bg-[#e5e5e5] text-center">
          <span className="pr-5 font-bold">Location:</span>
          <span className="">{location.state.position.location}</span>
        </li>
        <li className="text-xl mx-4 p-4 bg-[#e5e5e5] text-center">
          <span className="pr-5 font-bold">Department:</span>
          <span className="">{location.state.position.department}</span>
        </li>
      </ul>
      </div>
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold mt-16 ml-4 mr-32 mb-16">
          {location.state.position.title}
        </h1>
        <a
          href="/careercontact"
          className="flex w-[10%] h-1/6 items-center justify-center rounded-md bg-blue-700 py-2 px-3 text-m font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Apply Now
        </a>
      </div>
      <div className="flex-column">
        <h2 className="text-2xl mx-4 max-w-3xl">Position Overview</h2>
        <p className="mx-4 mb-16">{location.state.position.jobDescription}</p>
      </div>
    </div>
  );
};

export default CareerDetail;
