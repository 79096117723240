import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import engineerCad from '../assets/engineerCad.jpg'
import engineerdrawing from '../assets/engineer-drawing.jpg'

const features = [
  // {
  //   name: 'Keypoint 1.',
  //   description:
  //     'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
  //   icon: CloudArrowUpIcon,
  // },
  // {
  //   name: 'Keypoint 2.',
  //   description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
  //   icon: LockClosedIcon,
  // },
  // {
  //   name: 'Keypoint 3',
  //   description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
  //   icon: ServerIcon,
  // },
]

export default function Aboutus() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32 lg:pl-[12rem]">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pt-4 lg:pl-4">
            <div className="lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-600">About Us</p>
              <p className="mt-6 text-md leading-8 text-gray-600">
              <span className="font-semibold text-gray-900">Design & Data Solutions</span> is a leading provider of innovative solutions for plants and refineries, offering unparalleled expertise in SPI integration, consulting and training. 
              Our team of highly trained professionals has extensive experience in analyzing existing documentation systems and converting data into the SPI system, providing our clients with a seamless transition to this powerful software solution.
              <p>&nbsp;</p> 
              We work closely with our clients to understand their unique needs and determine the relevance of SPI to their operations. 
              Our comprehensive SPI consulting services help clients optimize their processes and maximize the benefits of this sophisticated technology. 
              From initial planning and design to implementation and support, we provide end-to-end solutions that enable plants and refineries to increase process uptime, reduce operating costs, and stay ahead of the competition..
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute top-1 left-1 h-5 w-5 text-blue-600" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              src={engineerdrawing}
              alt="Product screenshot"
              className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
