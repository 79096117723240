
import './App.css';
import Home from './pages/Home'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from './components/Footer';
import {navigation} from './navigation-links/Footer-navigation'
import Contact from './pages/Contact';
import About from './pages/About';
import ScrollToTop from "./components/ui/ScrollToTop";
import DataService from './pages/DataService';
import Automation from './pages/Automation';
import Project from './pages/Project';
import Navbar2 from './components/Navbar2';
import Career from './pages/Career';
import CareerDetail from './pages/CareerDetail';
import {positions} from './data/CareerData'
import CareerContact from './pages/CareerContact';



export default function App() {
  const pageId = window.location.pathname;
  return (
    <Router>
      <Navbar2 pageId={pageId} />
      <ScrollToTop>
          <Routes>
            <Route path="/"  element={<Home />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/about" element={<About />}/>
            <Route path="/engineeringdataservices" element={<DataService />}/>
            <Route path="/automation" element={<Automation />}/>
            <Route path="/project" element={<Project />}/>
            <Route path="/career" element={<Career positions={positions} />}/>
            <Route path="/jobID/:id" element={<CareerDetail />}/>
            <Route path="/careercontact" element={<CareerContact />}/>
          </Routes>
          <Footer footerNavigation={navigation}/>
      </ScrollToTop>
    </Router>
  );
}

