import Automationheader from "../components/Automationheader"
import AutomationContent from "../components/AutomationContent"

const Automation = () => {
    return(
        <>
            <Automationheader />
            <AutomationContent />
        </>
    )
}
export default Automation