import React from "react"
import logo from "../assets/logo-straight.png"

export default function Footer({footerNavigation}) {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">
      Footer
    </h2>
    <div className="mx-auto max-w-md px-6 pt-12 sm:max-w-7xl lg:px-8 lg:pt-16">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="space-y-8 xl:col-span-1">
          <img
            className="h-[8rem]"
            src={logo}
            alt="Company name"
          />
          <p className="text-base text-gray-400">
          Transform Your Data into Actionable Insights with Our Expert Solutions.
          </p>
        </div>
        <div className="mt-12 grid grid-cols-1 gap-8 xl:col-span-1 xl:mt-0">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 className="text-base font-medium text-white">Useful Links</h3>
              <ul role="list" className="mt-4 space-y-4">
                {footerNavigation.usefullinks.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-400 hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h3 className="text-base font-medium text-white">Support</h3>
              <ul role="list" className="mt-4 space-y-4">
                {footerNavigation.solutions.map((item) => (
                  <li key={item.name}>
                    <a href={item.href} className="text-base text-gray-400 hover:text-white">
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-10 xl:mt-0 lg:ml-20">
            <h3 className="text-m font-semibold leading-6 text-white">FOLLOW US</h3>
            <div className="flex mt-6 space-x-6">
            {footerNavigation.social.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-white">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
            <div className="mt-6 sm:flex sm:max-w-md">
              <div className="mt-4 sm:mt-0 sm:flex-shrink-0">
                <a
                  href="/contact"
                  className="flex w-full items-center justify-center rounded-md bg-blue-700 py-2 px-3 text-m font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 py-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {currentYear} Design & Data Solutions LLC. All rights reserved.
          </p>
        </div>
    </div>
  </footer>
  )
}

  