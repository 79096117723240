
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import emailjs from '@emailjs/browser'
import { useRef, useState } from 'react'
import SuccessNotification from '../components/SuccessNotification';

export default function Contactform() {
  const form = useRef();
  const [modal, setModal] = useState(false);

  async function sendEmail (e) {

    try {
      e.preventDefault();
      await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, 
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, 
        { publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,}
      )
      setModal(true);
      console.log(modal)
    } catch (error) {
      console.log('FAILED...', error.text);
    }

  };

  return (
    <div className="relative bg-blue-100">
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-100 py-16 px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            {window.location.pathname === '/contact' ? "Get in touch" : "Apply Today"}</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
            {window.location.pathname === '/contact' ? "Share your project needs with us, and we'll connect you with the most suitable point of contact to provide high-quality service and support." : "Send your resume and the job ID for which you want to apply."}
            
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>6200 Savoy Drive, Suite 1202</p>
                  <p>Houston, TX 77036</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">+1 (832) 910-8616</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">
                    {window.location.pathname === '/contact' ? "services@solutions-dd.com" : "hr@solutions-dd.com"}
                    </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            <form action="#" method="POST" className="grid grid-cols-1 gap-y-6" ref={form} onSubmit={sendEmail}>
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Full name
                </label>
                <input
                  type="text"
                  name="user_name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full rounded-md border-solid border-2 border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Full name"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="user_email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-solid border-2 border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Email"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  type="text"
                  name="user_phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full rounded-md border-solid border-2 border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Phone"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full rounded-md border-solid border-2 border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Message"
                  defaultValue={''}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-blue-700 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <>
        {modal ? <SuccessNotification /> : ''}
      </>
    </div>
  )
}
