import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Dropdown from "./Dropdown"
import logo from "../assets/logo-straight.png"


const navigation = [
  { name: "About Us", href: "/about" },
  { name: "Contact", href: "/contact" },
  { name: "Careers", href: "/career" }
]

const menuBarNav = [
    { name: "Home", href: "/" },
    { name: "Engineering Data Services", href: "/engineeringdataservices"},
    // { name: "Automation", href: "automation"},
    { name: "About Us", href: "/about" },
    { name: "Career", href: "/career"}
]

export default function Navbar2({ pageId }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      {/* Header */}
      <header
        className={`inset-x-0 top-0 z-50 ${
          pageId !== "/" ? "relative bg-gray-900" : "absolute bg-trasnparent"
        }`}
      >
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex justify-center items-center lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Data & Design Solutions</span>
              <img
                className="h-[8rem] w-auto"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className={`h-12 w-12 ${pageId !== "/" ? "text-blue-500" : "text-white"}`} aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <a key="Home" href="/" className={`text-lg font-semibold leading-6 no-underline text-white 
                ${pageId === "/" ? '!text-blue-600 border-b-2 border-blue-500' : '' }`}>
              Home
            </a>
            <Dropdown pageId={pageId} />
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={`text-lg font-semibold leading-6 no-underline text-white 
                ${pageId === item.href ? '!text-blue-600 border-b-2 border-blue-500' : '' }`}
              >
                {item.name}
              </a>
            ))}

          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Data Solutions LLC.</span>
                <img
                  className="h-[6rem] w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-12 w-12 text-blue-700" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {menuBarNav.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6">
                    <a
                      href="/contact"
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-blue-500 to-blue-700 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-purple-700 hover:to-indigo-700"
                    >
                      Contact Us
                    </a>
                  </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
