import {
  UserGroupIcon,
  Cog6ToothIcon,
  PresentationChartBarIcon,
  Square3Stack3DIcon
} from "@heroicons/react/20/solid";

import spi_intools from "../assets/spi_intools.jpg";
import smart3d from "../assets/smart3d.jpg";

const features = [
  {
    name: "Automation:",
    description:
      "SPI automates the entire lifecycle of instrumentation systems, reducing errors, saving time and resources, and improving quality and accuracy.",
    icon: Cog6ToothIcon,
  },
  {
    name: "Integration:",
    description:
      "SPI integrates with other software such as AutoCAD and SmartPlant P&ID to provide a comprehensive solution for instrumentation design and management. This integration ensures data consistency, reducing errors and improving efficiency.",
    icon: Square3Stack3DIcon,
  },
  // {
  //   name: "Visualization",
  //   description:
  //     "SPI enables engineers to work in a 3D environment, visualizing the layout of instruments and control systems. This helps identify design issues before construction, saving costs and increasing efficiency. It also fosters better communication and collaboration among project teams, leading to better decision-making.",
  //   icon: PresentationChartBarIcon,
  // },
];

const features2 = [
  {
    name: "Intelligent design automation:",
    description:
      "Smart 3D automates design tasks, generating layouts and detecting clashes and materials to ensure accurate 3D models of industrial plants.",
    icon: Cog6ToothIcon,
  },
  {
    name: "Real-time collaboration:",
    description:
      "Smart 3D facilitates real-time collaboration between teams and stakeholders by allowing them to view, mark up, and comment on 3D models, improving communication and decision-making.",
    icon: UserGroupIcon,
  },
  {
    name: "Integration:",
    description:
      "Smart 3D integrates with SmartPlant P&ID and SmartPlant Instrumentation to ensure consistent data across systems, reducing errors and improving efficiency in industrial plant design and management.",
    icon: Square3Stack3DIcon,
  },
];

const renderrightimage1 = () => {
    return(
        <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-700">
                  SmartPlant Instrumentation
                </p>
                <p className="mt-6 text-m leading-8 text-gray-600">
                SmartPlant Instrumentation (SPI) is an innovative and cutting-edge software solution that revolutionizes the way plants and refineries manage their instrumentation data. By consolidating data into a centralized system, SPI empowers plant managers to streamline processes, optimize performance, and significantly reduce operating costs. 
                This intelligent software also standardizes procedures, providing a robust framework for automation that ensures maximum efficiency and process uptime.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute top-1 left-1 h-5 w-5 text-blue-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              src={spi_intools}
              alt="Product screenshot"
              className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    )
}
const rightimage1 = renderrightimage1();

const renderleftimage2 = () => {
    return(
        <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:ml-auto lg:pt-4 lg:pl-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-700">
                  SmartPlant 3D
                </p>
                <p className="mt-6 text-m leading-8 text-gray-600">
                Smart 3D offers a range of advanced features, including intelligent design automation, clash detection, 
                and material tracking, which help to reduce design errors, improve efficiency, and ensure project delivery on time and within budget.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features2.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute top-1 left-1 h-5 w-5 text-blue-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="flex items-start justify-end lg:order-first">
              <img
                src={smart3d}
                alt="Product screenshot"
                className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>
      </div>
    )
}
const leftimage2 = renderleftimage2();

export default function DataServiceContent() {
  return (
    <>
        {rightimage1}
        {leftimage2}
    </>
  );
}
