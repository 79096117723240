import React from "react";
import NewCareerTable from "../components/NewCareerTable";

const Career = ({ positions }) => {
  return (
    <div>
      <div className="my-8 text-xl">
        <div className="w-2/4 mx-auto">
          <NewCareerTable positions={positions} />
        </div>
      </div>
    </div>
  );
};

export default Career;
