import Contactform from '../components/Contactform'

const Contact = () => {

    return(
        <>
            <Contactform />
        </>
    )
}

export default Contact