import CTA from "../components/CTA";
import Feature1 from "../components/Feature1";
import Hero2 from "../components/Hero";




const Home = () => {
    return(
        <>
            <Hero2 />
            <Feature1 />
            <CTA />
        </>
    )
}

export default Home