import Aboutus from "../components/Aboutus"


const About = () => {
    return(
        <>
            <Aboutus />
        </>
    )
}
export default About