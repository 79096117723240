import { PlusIcon } from '@heroicons/react/20/solid'

export default function Example() {
  return (
    <div className="text-center mt-10">
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No Position Opens</h3>
      <p className="mt-1 text-sm text-gray-500">We will post any new position when available.</p>
      <div className="mt-6">
        <a
          href='/careercontact'
          className="inline-flex items-center rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Contact Us
        </a>
      </div>
    </div>
  )
}
