export default function Automationheader() {
    return (
      <div className="mx-auto mt-6 max-w-7xl sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 py-16 text-center shadow-2xl sm:rounded-3xl sm:px-16">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Automation</h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
            Design & Data Solutions offers automation services, including loop checks and equipment reviews, 
            through its team of Subject Matter Experts.
            </p>
          </div>
        </div>
      </div>

    )
}