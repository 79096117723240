import {
    CloudArrowUpIcon,
    LockClosedIcon,
    ServerIcon,
} from "@heroicons/react/20/solid";
import PIDdrawing from "../assets/PID-drawing.jpeg"
  
  const features = [
    {
      name: "Data Migrations and Importing.",
      description:
        "Our ability to proficiently execute the AutoCAD to SPI conversion process is backed by our expertise in utilizing import utilities and instrument index reports for efficient data migration.",
      icon: CloudArrowUpIcon,
    },
    {
      name: "SPI Database Admin Services.",
      description:
        "We offer expertise in managing and administering the SPI system, its configuration, and its integration with other enterprise systems, ensuring proper data integrity, user management, and the smooth operation of instrumentation data within the facility.",
      icon: LockClosedIcon,
    },
    // {
    //   name: "Data Analysis and Optimization.",
    //   description:
    //     "",
    //   icon: ServerIcon,
    // },
  ];
  
  export default function Feature1() {
    return (
      <div className="">
        <div className="overflow-hidden bg-gray-200 py-24 lg:pr-[5rem] sm:pr-0">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  {/* <h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                    Unparalled serviced
                  </h2> */}
                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Our Expertise
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                  <span className="font-semibold leading-8 tracking-tight text-blue-600">Design & Data Solution</span> offers comprehensive SmartPlant Instrumentation (SPI / Intools) integration services, 
                  including consulting, training, and analysis of existing documentation systems for optimization.
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                      <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            className="absolute top-1 left-1 h-5 w-5 text-blue-700"
                            aria-hidden="true"
                          />
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src={PIDdrawing}
                alt="Product screenshot"
                className="lg:max-w-2xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
                height={1442}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
  