import DataServiceContent from "../components/DataServiceContent"
import DataServiceHeader from "../components/DataServiceHeader"

const DataService = () => {
    return (
        <> 
            <DataServiceHeader />
            <DataServiceContent />
        </>
    )

}
export default DataService