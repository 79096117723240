  import {
    CloudArrowUpIcon,
    LockClosedIcon,
    ServerIcon,
  } from "@heroicons/react/20/solid";
  
  const automation1 = [
    {
      name: "New PLC/HMI Development & Implementation",
      description:""
    },
    {
      name: "Solutions for Retrofit & Upgrading Existing Systems",
      description:"",
    },
    {
      name: "Distributed Control systems",
      description:"",
    },
    {
      name: "Custom Builds & Specialized Graphical Interfaces",
      description:"",
    },
    {
      name: "Support from Design to Installation & Commissioning",
      description:"",
    }
  ];
  const automation2 = [
    {
      name: "On Site Device Verification",
      description:""
    },
    {
      name: "Loop Checks for New or Existing Facilities",
      description:"",
    },
    {
      name: "Spot Loop Calibrations",
      description:"",
    },
    {
      name: "End Device Regulatory Documentation Creation",
      description:"",
    }
  ];
  const automation3 = [
    {
      name: "Annual device testing & documentation in accordance with OSHA Standards",
      description:"Critical instrument tests are performed on running facilities by highly skilled programmers and instruments technicians working together with operations to ensure field devices are identifiable, functioning, and calibrated correctly."
    },

  ];
  const automation4 = [
    {
      name: "Development of new control narratives",
      description:""
    },
    {
      name: "Update existing or edit current control narratives",
      description:"",
    },
  ];
  const automation5 = [
    {
      name: "Design Distributed Control Network",
      description:""
    },
    {
      name: "Add Remote Nodes to Current Systems",
      description:"",
    },
    {
      name: "Troubleshoot Network Communications",
      description:"",
    },
  ];
  
  const renderrightimage1 = () => {
      return(
          <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-600">
                    PLC/HMI Desgin & Development
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">  
                  Our range of services includes:
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {automation1.map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt className="inline font-semibold text-gray-900">
                          {feature.name}
                        </dt>
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                alt="Product screenshot"
                className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>
      )
  }
  const rightimage1 = renderrightimage1();
  
  const renderleftimage1 = () => {
      return(
          <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:ml-auto lg:pt-4 lg:pl-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-600">
                    Field Commissioning/Loop Checks & Calibrations
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                  Our range of services includes:
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {automation2.map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt className="inline font-semibold text-gray-900">
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="flex items-start justify-end lg:order-first">
                <img
                  src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                  alt="Product screenshot"
                  className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>
      )
  }
  const leftimage1 = renderleftimage1();
  
  const renderrightimage2 = () => {
      return(
          <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-8 lg:pt-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-600">
                    Critical Instrument/PSM Testing
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                  Our range of services includes:
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {automation3.map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt className="inline font-semibold text-gray-900">
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <img
                src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                alt="Product screenshot"
                className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>
      )
  }
  const rightimage2 = renderrightimage2();
  
  const renderleftimage2 = () => {
      return(
          <div className="overflow-hidden bg-white py-24 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:ml-auto lg:pt-4 lg:pl-4">
                <div className="lg:max-w-lg">
                  <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-600">
                    Control Narratives
                  </p>
                  <p className="mt-6 text-lg leading-8 text-gray-600">
                    Our range of services includes:
                  </p>
                  <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    {automation4.map((feature) => (
                      <div key={feature.name} className="relative">
                        <dt className="inline font-semibold text-gray-900">
                          {feature.name}
                        </dt>{" "}
                        <dd className="inline">{feature.description}</dd>
                      </div>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="flex items-start justify-end lg:order-first">
                <img
                  src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                  alt="Product screenshot"
                  className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
                  width={2432}
                  height={1442}
                />
              </div>
            </div>
          </div>
        </div>
      )
  }
  const leftimage2 = renderleftimage2();

const renderrightimage3 = () => {
    return(
        <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-600">
                    Network Development & Troubleshooting
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                Our range of services includes:
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {automation5.map((feature) => (
                    <div key={feature.name} className="relative">
                      <dt className="inline font-semibold text-gray-900">
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="Product screenshot"
              className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>
    )
}
const rightimage3 = renderrightimage3();

const renderleftimage3 = () => {
    return(
        <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:ml-auto lg:pt-4 lg:pl-4">
              <div className="lg:max-w-lg">
                <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl text-blue-600">
                    Reliability & Process Reviews
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                At Data & Design Solutions, our team of highly skilled Subject Matter Experts specializes in conducting reviews, 
                simulations, and testing for mechanical, electrical, and process systems, with the goal of maximizing their efficiency.
                </p>
              </div>
            </div>
            <div className="flex items-start justify-end lg:order-first">
              <img
                src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                alt="Product screenshot"
                className="lg:max-w-4xl rounded-xl ring-1 ring-gray-400/10 shadow-2xl lg:ml-0"
                width={2432}
                height={1442}
              />
            </div>
          </div>
        </div>
      </div>
    )
}
const leftimage3 = renderleftimage3();
  
  export default function DataServiceContent() {
    return (
      <>
          {rightimage1}
          {leftimage1}
          {rightimage2}
          {leftimage2}
          {rightimage3}
          {leftimage3}
      </>
    );
  }
  