import { CalendarIcon, MapPinIcon, UsersIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom"//used to navigate to detailed job page url
import Zerostate from "./Zerostate"

export default function Example({ positions }) {
  const navigate = useNavigate();//used to navigate to detailed job page url
  const handleClick = (position) => {
    navigate(`/jobID/${position.id}`, {state: {position:position}});//navigate to detailed job page. pass specific job data object
  };
  console.log(positions)
  return (
    <div>
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">
          Job Postings
        </h3>
        <p className="mt-1 text-sm text-gray-500">
            {positions.length} jobs
        </p>
      </div>
      {positions.length === 0 
      ? <Zerostate /> 
      :       
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {positions.map((position) => (
          <li key={position.id}>
            <span onClick={ () => {
              handleClick(position)
            }}
            className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="truncate text-sm font-medium text-indigo-600">
                    {position.title}
                  </p>
                  <div className="ml-2 flex flex-shrink-0">
                    <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                      {position.type}
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <UsersIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      {position.department}
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      <MapPinIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      {position.location}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <p>
                      Closing on{" "}
                      <time dateTime={position.closeDate}>
                        {position.closeDateFull}
                      </time>
                    </p>
                  </div>
                </div>
              </div>
            </span>
          </li>
        ))}
      </ul>
    </div>}
    </div>
  );
}
