import refinery from "../assets/refinery.jpg";
import refinery2 from "../assets/refinery2.jpg";
import refinery3 from "../assets/chemical-plant.jpg"
import Carousel from "react-bootstrap/Carousel";

const carouselImg = [
  {
    id: 1,
    image:refinery
  },
  { 
    id: 2,
    image:refinery2
  },
  {
    id: 3,
    image:refinery3
  }
]

export default function Hero2() {

  return (
    <main>
      {/* Hero section */}
      <div className="relative isolate overflow-hidden bg-gray-900 pt-14 pb-16 sm:pb-20 h-screen">
        <Carousel fade controls={false} indicators={false} className="!absolute inset-0 -z-20 opacity-40 h-full w-full object-cover">
          {carouselImg.map((carousel) => (
           <Carousel.Item key={carousel.id} interval={4000}>
            <img
              src={carousel.image}
              alt=""
              className="h-full w-full object-cover"
            />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
            <div className="text-center flex-col items-center justify-between">
              <h1 className="text-4xl font-bold tracking-tight text-white lg:text-4xl">
                {/* Data Optimization and Solutions for the Industrial Enterprise */}
                Revolutionize Your Plant Performance with Data Solutions
              </h1>
              <div className="h-[50px]" />
              <p className="mt-6 lg:text-[24px] leading-8 text-white !sm:text-[18px]">
                Our goal is to provide quality solutions that will take the
                stress out of data management.
              </p>
              <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
                <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                  <a
                    href="/engineeringdataservices"
                    className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-blue-700 shadow-sm hover:bg-indigo-50 sm:px-8"
                  >
                    Learn More
                  </a>
                  <a
                    href="/contact"
                    className="flex items-center justify-center rounded-md border border-transparent bg-blue-700 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-80 sm:px-8"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Logo cloud */}
          {/* <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/transistor-logo-white.svg"
              alt="Transistor"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/reform-logo-white.svg"
              alt="Reform"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-white.svg"
              alt="Tuple"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/savvycal-logo-white.svg"
              alt="SavvyCal"
              width={158}
              height={48}
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/statamic-logo-white.svg"
              alt="Statamic"
              width={158}
              height={48}
            />
          </div> */}
        </div>
        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
          <svg
            className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
              fillOpacity=".2"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </main>
  );
}
